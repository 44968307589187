import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "./dist/output.css";
import './App.css';

import Calculator from "./components/Calculator";
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route exact path='/' element={<Calculator />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
